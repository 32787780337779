<template>
  <div class="reset-password">
    <div class="box-title flex flex-between">
      <p class="title">重置密码</p>
      <p class="login" @click="toLogin">登录</p>
    </div>
    <div class="box">
      <div class="box-input flex flex-between align-center">
        <input v-model="password" :type="oneShow ? 'text' : 'password'" placeholder="输入密码" />
         <img
          v-show="!oneShow"
          src="@/assets/img/loginRegister/no-eye.png"
          alt=""
          @click="eyeFun('one', true)"
        />
        <img
          v-show="oneShow"
          src="@/assets/img/loginRegister/eye.png"
          alt=""
          @click="eyeFun('one', false)"
        />
      </div>
    </div>
    <div class="box">
      <div class="box-input flex flex-between align-center">
        <input
          v-model="againPassword"
          :type="twoShow ? 'text' : 'password'"
          placeholder="再次输入密码"
        />
        <img
          v-show="!twoShow"
          src="@/assets/img/loginRegister/no-eye.png"
          alt=""
          @click="eyeFun('two', true)"
        />
        <img
          v-show="twoShow"
          src="@/assets/img/loginRegister/eye.png"
          alt=""
          @click="eyeFun('two', false)"
        />
      </div>
    </div>
    <p class="tip">密码为8-12位，需要有英文大小写</p>
    <p :class="['finish', { 'finish-sure': (this.password || this.password === 0) && (this.againPassword || this.againPassword === 0) }]" @click="finishFun">完成重置密码</p>
    <van-popup
      v-model:show="showSuccess"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="reset-password-popup"
    >
    <div class="box-reset">
      <img src="@/assets/img/loginRegister/success.png" alt="">
      <p class="box-reset-text">重置密码成功</p>
      <p class="box-reset-tip">您的登录密码已重置<br>请去重新登录，开启灵龙购物体验</p>
      <p class="box-reset-login" @click="toLogin">去登录</p>
    </div>
    </van-popup>
    <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
  </div>
</template>
<script>
import ToastBox from "../../components/toast.vue";
import {
    findPassword
} from '@/utils/loginRegister.js';
export default {
  name: "ResetPassword",
  components: { 
    ToastBox
  },
  data() {
    return {
      twoShow: false,
      oneShow: false,
      showToast: false,
      password: "",
      againPassword: "",
      showSuccess: false
    };
  },
  methods: {
    handleSure(val) {
      this.showToast = val;
    },
    eyeFun(name, val) {
      this[name + "Show"] = val;
    },
    toLogin() {
      this.$router.push("/login")
    },
    finishFun() {
      if (!this.password && this.password !== 0) {
        return
      } else if (!/^[0-9A-Za-z]{8,16}$/.test(this.password) || /^[0-9]{8,16}$/.test(this.password)) {
        this.msg = "密码设置的格式不正确<br>请输入8-16位数字、英文字母的<br>登录密码";
        this.showToast = true;
        return
      }
      if (!this.againPassword && this.againPassword !== 0) {
        return
      } else if (String(this.password) !== String(this.againPassword)) {
        this.msg = "两次密码设置不一致<br>请重新输入密码";
        this.showToast = true;
        return
      }
      let info = sessionStorage.getItem("forgetInfo")
      info = info ? JSON.parse(info) : ""
      if (!info) {
        return this.$toast("请从正确方式进入该页面")
      }
      let data = {
          username: info.phone,
          password: this.password,
          code: info.smsCode
      };
      findPassword(data).then(res => {
        if (res && res.errorCode == "0000") {
          this.showSuccess = true;
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.reset-password {
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  .box-title{
    width: 315px;
    height: 33px;
    margin: 30px auto 35px;
    .title {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      text-align: left;
    }
    .login{
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E62129;
      line-height: 18px;
      margin-top: 5px;
    }
  }
  .box {
    width: 315px;
    margin: 0 auto;
    border-bottom: solid 1px #f5f5f5;
    text-align: left;
    .box-input {
      height: 22px;
      padding: 25px 0 15px 0;
      input {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      input::-webkit-input-placeholder {
        color: #cccccc;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .tip {
    width: 315px;
    height: 16px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin: 10px auto 54px;
    text-align: left;
  }
  .finish {
    width: 315px;
    height: 44px;
    background: #e62129;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    border-radius: 22px;
    opacity: 0.5;
    margin: 0 auto;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
  }
  .finish-sure {
    opacity: 1;
  }
}
</style>
<style lang="scss">
.reset-password-popup {
  width: 280px !important;
  top: 188px !important;
  transform: translateX(-50%) !important;
  background: none !important;
  overflow: unset !important;
  .box-reset{
    position: relative;
    width: 280px;
    height: 152px;
    background: #FFFFFF;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    padding-top: 33px;
    img{
      width: 64px;
      height: 64px;
      position: absolute;
      left: 108px;
      top: -41px;
    }
    &-text{
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    &-tip{
      height: 44px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
      margin: 20px 0;
    }
    &-login{
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #E62129;
      border-top: solid 1px #F0F0F0;
    }
  }
}
</style>